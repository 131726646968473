<template src="./TotalOrder.html"></template>
<style scoped src="./TotalOrder.scss" lang="scss"></style>

<script>
import { numberComma } from "@/utils/number.util";

export default {
  name: 'TotalOrder',
  props : {
    totalOrder: [Number, String],
    totalPrice : [Number, String]
  },
  data: function () {
    return {
    }
  },
  computed: {
    sumQuantity () {
      return numberComma(this.totalOrder)
    },

    totalPriceString() {
      return numberComma(this.totalPrice)
    }
  }
}
</script>